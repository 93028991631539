import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGithub,
  faTwitter,
  faStackOverflow,
  faDev,
  faMedium,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import tw from 'tailwind.macro'
import { css } from '@emotion/core'


const splStyle = {
    fontSize: '2rem',
    margin: '0.25rem 0.5rem',
    transform: 'perspective(1px) translateZ(0)',
    boxShadow: '0 0 1px transparent',
}

const SocialHandles = ({ siteMetadata }) => {
  return (
    <div css={css(tw`flex justify-center`)}>
      <a
        style={splStyle}
        href={siteMetadata.linkedInHandle}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a
        style={splStyle}
        href={siteMetadata.emailHandle}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
      <a
        style={splStyle}
        href={siteMetadata.githubHandle}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faGithub} />
      </a>
      <a
        style={splStyle}
        href={siteMetadata.twitterHandle}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      {/* <a
        href={siteMetadata.stackOverflowHandle}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faStackOverflow} />
      </a> */}
      {/* <a
        style={splStyle}
        href={siteMetadata.devToHandle}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faDev} />
      </a> */}
      <a
        style={splStyle}
        href={siteMetadata.mediumHandle}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faMedium} />
      </a>
      
    </div>
  )
}

export default SocialHandles