import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="p" components={components}>{`Hey there 👋`}</MDXTag>
      <MDXTag name="p" components={components}>{`I'm a Software Engineer turned Data Scientist based out of Vancouver, Canada. Having specialized in Big Data from Simon Fraser University, I now work as a Data Scientist with Shopify.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Outside of work, I'm a dancer and an athlete. Visit `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://dance.pallavibharadwaj.com"
        }}>{`my dance portfolio`}</MDXTag>{` to know more.`}</MDXTag>
      <MDXTag name="p" components={components}>{`If you have any relevant work I can contribute to, do not hesitate to `}<b><MDXTag name="a" components={components} parentName="p" props={{
            "href": "mailto:pallavi.rbharadwaj@gmail.com"
          }}>{`mail me`}</MDXTag></b>{`. If you liked anything you read here, I would love to hear from you!`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      