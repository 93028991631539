import React,{useEffect} from 'react'
import { graphql } from 'gatsby'
import tw from 'tailwind.macro'
import { css } from '@emotion/core'

import Layout from 'components/Layout'
import Link from 'components/Link'
import Button from 'components/Button'
import Container from 'components/Container'
import { useTheme, ThemeProvider, themes } from '../components/Theming'
import Home from './home.mdx'
import FavArticles from "./favs.mdx"
import SocialHandles from "../components/SocialHandles"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGithub,
  faTwitter,
  faStackOverflow,
  faDev,
  faMedium,
  // faLinkedin,
} from '@fortawesome/free-brands-svg-icons'

const handles = {
  githubHandle: "https://github.com/pallavibharadwaj",
  twitterHandle: "https://www.twitter.com/pallavibharadwj",
  mediumHandle: "https://medium.com/@pallavibharadwaj",
  linkedInHandle: "https://in.linkedin.com/in/pallavirbharadwaj",
  emailHandle:"mailto:pallavi.rbharadwaj@gmail.com"
}
const socialMediaIcons = [
  {
    url: 'https://github.com/pallavibharadwaj',
    className: 'fa-github-square',
  },
  {
    url: 'https://in.linkedin.com/in/pallavirbharadwaj',
    className: 'fa-linkedin',
  },
  {
    url: 'https://www.twitter.com/pallavibharadwj',
    className: 'fa-twitter',
  },
  {
    url: 'mailto:pallavi.rbharadwaj@gmail.com',
    className: 'fa fa-envelope-open',
  },
];

export default function Index({ data: { site, allMdx } }) {
  //const theme = useTheme()

  return (
    <Layout site={site} showImg="True">
      {/* <Test /> */}
      <Container
        css={css`
          padding-bottom: 0;
        `}
      >
        <Home />
        {/* <p> Test test'u dhaan super test'u dhaan :D</p> */}
         <SocialHandles siteMetadata={handles} />
        <br/>
        <FavArticles />
        <Button
          secondary
          to="/articles"
          aria-label="Visit blog page"
          css={css(tw`mt-8 md:mt-10 lg:mt-12`)}
        >
          Browse all my articles
        </Button>
        <hr />
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      limit: 8
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { ne: false } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 190)
          id
          fields {
            title
            slug
            date
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            banner {
              childImageSharp {
                sizes(maxWidth: 720) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            slug
            keywords
          }
        }
      }
    }
  }
`
